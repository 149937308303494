import { useEffect, useState, useContext } from 'react';
import './index.scss';
import { Loader } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { apiResponse } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RequestAccessSubmitted } from '../access-request-pages/access-request-submitted';
import { AppContext } from '../../contexts/app-context';

interface RequestAccessFormProps {
  onRequestAccessModalClosed: () => void;
  // requestAccessSource: string;
}

const RequestAccessForm = (props: RequestAccessFormProps): JSX.Element => {
  const { onRequestAccessModalClosed } = props;

  const page = [
    { name: 'Home', isChecked: false },
    { name: 'Brand', isChecked: false },
    { name: 'People', isChecked: false },
    { name: 'Pipeline', isChecked: false },
    { name: 'Supply', isChecked: false },
    { name: 'Manufacturing', isChecked: false },
    { name: 'Finance', isChecked: false },
    { name: 'Prioritized Agenda', isChecked: false },
    { name: 'External', isChecked: false },
    { name: 'Key Events', isChecked: false },
  ];

  const [isLoading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  // const [userName, setUserName] = useState("");
  const [userID, setUserID] = useState('');
  const [Comments, setComments] = useState('');
  const [checkedFeatures, setCheckedFeatures] = useState(page);
  const [userInfo, setUserInfo] = useState<any>(null);
  const { accessRequestSuccessful, setAccessRequestSuccessful } = useContext(AppContext);
  const [emailOptOut, setEmailOptOut] = useState(false);

  const checkHandler = (e: any) => {
    const newCheckboxes = [...checkedFeatures];
    newCheckboxes.map((page: any) => {
      if (page.name === e) {
        page.isChecked = !page.isChecked;
        setCheckedFeatures(newCheckboxes);
      }
    });
  };

  useEffect(() => {
    const pageList = checkedFeatures.filter((page: any) => page.isChecked);
    if (pageList.length > 0 && userInfo !== null) {
      setDisabled(false);
    } else setDisabled(true);
  }, [checkedFeatures, userInfo]);

  const submitAccessRequest = async () => {
    const accessList: object[] = [];

    //for updating page_tile_list in payload
    checkedFeatures.map((page: any) => {
      if (page.isChecked) {
        accessList.push({
          tile_name: '',
          page_name: page.name,
        });
      }
    });
    const payload = {
      page_tile_list: accessList,
      user_id: userID.split('@')[0],
      justification: Comments, //Remove After confirmation about Access form
      notify_off: emailOptOut,
    };
    setLoading(true);
    try {
      const res = await apiResponse('post', 'submit-access-request', [], payload);
      if (res?.data.data) {
        setLoading(false);
        setCheckedFeatures(page);
        setUserID('');
        setComments('');
        setUserInfo(null);
        toast.success('Access request has been generated & sent to Sensing Approver');
        setAccessRequestSuccessful(true);
      } else {
        setLoading(false);
        toast.error('Failed to Submit Request!!');
      }
    } catch (error) {
      toast.error('Something Went Wrong!!');
      setLoading(false);
      console.log(error);
    }
  };
  const getUserDetail = async () => {
    const payload = {
      login_name: userID.split('@')[0],
    };

    setBtnLoading(true);
    try {
      const res = await apiResponse('post', 'get-user-access-details', [], payload);
      if (res?.data.data) {
        setBtnLoading(false);
        let user_details = res.data.data.user_details;
        setUserInfo(user_details);
        if (user_details.length < 1) {
          setUserID('');
          setComments('');
          setCheckedFeatures(page);
          setUserInfo(null);
          toast.warn('User does not exist');
        }
      } else {
        setBtnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    setUserID('');
    setComments('');
    setCheckedFeatures(page);
    setUserInfo(null);
  }, [onRequestAccessModalClosed]);

  return isLoading ? (
    <div className="adm-loader-container">
      <Loader />
    </div>
  ) : !isLoading && accessRequestSuccessful ? (
    <RequestAccessSubmitted />
  ) : (
    !isLoading && (
      <form className="request-access">
        <div className="request-access__user-info">
          <div className="request-access__row-field">
            <label htmlFor="Amgen Email" className="request-access__label ops-fs-5 ops-fw-bold">
              Amgen Email
              <sup className="sup-star">*</sup>
            </label>
            <div className="input-with-icon">
              <input
                type="email"
                id="Amgen Email"
                className="request-access__input ops-fs-5"
                value={userID}
                onChange={e => {
                  setUserID(e.target.value);
                  setDisabled(true);
                }}
              ></input>
              {btnLoading ? (
                <div className="request-access-loader icon-loading"></div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="icon-search"
                  viewBox="0 0 16 16"
                  onClick={getUserDetail}
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              )}
            </div>
          </div>
          <div className="request-access__row-field">
            <label htmlFor="user-name" className="request-access__label ops-fs-5 ops-fw-bold">
              User Name
            </label>
            <input
              type="text"
              id="user-name"
              className="request-access__input ops-fs-5"
              value={userInfo !== null ? userInfo[0]?.Full_Name : ''}
              disabled
            ></input>
          </div>
        </div>
        <div className="request-access__user-info">
          <div className="request-access__row-field">
            <label className="request-access__label ops-fs-5 ops-fw-bold">Business Function</label>
            <input type="text" className="request-access__input ops-fs-5" value={userInfo !== null ? userInfo[0]?.Function : ''} disabled></input>
          </div>
          <div className="request-access__row-field">
            <label className="request-access__label ops-fs-5 ops-fw-bold">Role</label>
            <input type="text" className="request-access__input ops-fs-5" value={userInfo !== null ? userInfo[0]?.Role : ''} disabled></input>
          </div>
        </div>
        <hr></hr>
        <h6 className="request-access__h4 ops-fs-5 ops-fw-bold">
          Page Access
          <sup className="sup-star">*</sup>
        </h6>
        <span className="request-access__sub-heading ops-fs-5">The domains mentioned below encompass all pages within Sensing</span>
        <div className="feature-container">
          {checkedFeatures.map((option: any) => {
            return (
              <div key={option.name}>
                <span className="checkbox-option">
                  <input
                    type={'checkbox'}
                    key={option.name}
                    id={option.name}
                    value={option.name}
                    checked={option.isChecked}
                    onChange={() => checkHandler(option.name)}
                    className="checkbox-field"
                  ></input>
                  <label className="checkbox-label  ops-fs-5" htmlFor={option.name}>
                    {option.name}
                  </label>
                </span>
              </div>
            );
          })}
        </div>
        <div>
          <label htmlFor="Comments" className="request-access__label ops-fs-5 ops-fw-bold">
            Comments
          </label>
          <textarea
            placeholder=""
            id="Comments"
            className="request-access__textarea ops-fs-5"
            value={Comments}
            onChange={e => setComments(e.target.value)}
          ></textarea>
        </div>
        <div>
          <span className="checkbox-option">
            <input
              type={'checkbox'}
              id={'email_opt_out'}
              checked={emailOptOut}
              onChange={() => {
                setEmailOptOut(!emailOptOut);
              }}
              className="checkbox-field"
            ></input>
            <label className="checkbox-label  ops-fs-4">Email opt-out</label>
          </span>
        </div>
        <Button type="secondary" className="request-access__button" onClick={submitAccessRequest} disabled={isDisabled} text="submit" />
      </form>
    )
  );
};

export default RequestAccessForm;
